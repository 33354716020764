import { MODULES } from './constants/moduleConstants'

export default {
  items: [
    {
      name: 'Home',
      url: '/home',
      icon: 'icon-home'
    },
    {
      name: 'Getting Started',
      url: '/gettingstarted',
      icon: 'icon-control-play'
    },
    {
      name: 'Users',
      icon: 'icon-user-follow',
      children: [
        {
          name: 'Active Users',
          url: '/users/activeusers'
        },
        {
          name: 'Blocked Users',
          url: '/users/blockedlist'
        },
        {
          name: 'User Attributes',
          url: '/users/userattributes'
        }
      ]
    },
    {
      name: 'Settings',
      url: '/settings',
      icon: 'icon-settings',
      children: [
        {
          name: 'General Settings',
          url: '/settings/generalsettings'
        },
        {
          name: 'Notification Settings',
          url: '/settings/notificationsettings'
        }
      ]
    },
    {
      name: 'Modules',
      url: '/modules',
      icon: 'fa fa-diamond',
      children: [
        {
          name: 'Goals',
          url: '/goalsettings',
          data: {
            moduleKey: MODULES.GOALS
          }
        },
        {
          name: 'Meetings',
          url: '/meetingsettings',
          data: {
            moduleKey: MODULES.MEETINGS
          }
        },
        {
          name: 'Tasks',
          url: '/tasksettings',
          data: {
            moduleKey: MODULES.TASKS
          }
        },
        {
          name: 'Reviews',
          url: '/reviewsettings',
          data: {
            moduleKey: MODULES.REVIEWS
          }
        },
        {
          name: 'Surveys',
          url: '/surveysettings',
          data: {
            moduleKey: MODULES.SURVEYS
          }
        },
        {
          name: 'Feedback',
          url: '/feedbacksettings',
          data: {
            moduleKey: MODULES.FEEDBACK
          }
        },
        {
          name: 'Recognitions',
          url: '/recognitionsettings',
          data: {
            moduleKey: MODULES.RECOGNITIONS
          }
        },
        {
          name: 'Notes',
          url: '/notesettings',
          data: {
            moduleKey: MODULES.NOTES
          }
        }
      ]
    },
    {
      name: 'Intranet',
      url: '/intranet',
      icon: 'fa-regular fa-building',
      isProPath: true,
      data: {
        moduleKey: MODULES.MY_ORGANIZATION
      }
    },
    {
      name: 'Career',
      icon: 'icon-briefcase',
      children: [
        {
          name: 'Roles (Job titles)',
          url: '/career/roles',
          data: {
            moduleKey: MODULES.ROLES
          }
        },
        {
          name: 'Competency Library',
          url: '/career/competencylibrary'
        },
        {
          name: 'Individual Development Plan',
          url: '/career/individual-development-plan',
          isProPath: true,
          data: {
            moduleKey: MODULES.INDIVIDUAL_DEVELOPMENT_PLAN
          }
        },
        {
          name: 'Succession Plan',
          url: '/career/succession-plan',
          isProPath: true,
          data: {
            moduleKey: MODULES.SUCCESSION_PLAN
          }
        },
        {
          name: 'Learning Materials',
          url: '/career/learningmaterials',
          isProPath: true,
          data: {
            moduleKey: MODULES.LEARNING_MATERIALS
          }
        }
      ]
    },
    {
      name: 'Focus People',
      url: '/focuspeople',
      icon: 'icon-people',
      children: [
        {
          name: 'Types',
          url: '/focustypes',
          icon: 'icon-cursor'
        },
        {
          name: 'Relationships',
          url: '/focuspeople',
          icon: 'icon-cursor'
        }
      ]
    },
    {
      name: 'Reports',
      url: '/reports',
      icon: 'icon-pie-chart'
    },
    {
      name: 'Security',
      url: '/security',
      icon: 'icon-lock'
    },
    {
      name: 'Audit Logs',
      url: '/logs',
      icon: 'icon-shield'
    },
    {
      name: 'Licenses',
      url: '/licenses',
      icon: 'icon-info'
    },
    {
      name: 'Referral program',
      url: '/referralprogram',
      icon: 'pi pi-gift'
    },
    {
      name: 'Support',
      url: '/support',
      icon: 'icon-support'
    },
    {
      name: 'Integrations & API',
      icon: 'icon-link',
      children: [
        {
          name: 'API keys',
          url: '/integrationsandapi/apikeys'
        },
        {
          name: 'HRIS integration',
          url: '/integrationsandapi/hrisintegrations',
          isProPath: true
        }
      ]
    }
  ]
}
