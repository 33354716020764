import axios from 'axios'
import React, { createContext, useContext, useState, useRef } from 'react'
import { MODULES } from '../constants/moduleConstants'
import moment from 'moment'
import navConfig from '../_nav'

// #region Context and Variables ==========================================================

export const SettingsContext = createContext()

const SETTINGS_TTL = 3 // In minute

// #endregion

const SettingsContextProvider = props => {
  const [settings, setSettings] = useState({})
  const [isLoading, setIsLoading] = useState(true)
  const [enabledGoalProgressTypes, setEnabledGoalProgressTypes] = useState([])
  const [hasAdminConsent, setHasAdminConsent] = useState(true)
  const [validUntil, setValidUntil] = useState(moment().add(SETTINGS_TTL, 'minutes'))

  const disableMessageRef = useRef(null)

  // #region Mounting Functions ===========================================================

  /**
   * Fetches tenant settings from the API and sets the settings to the state.
   */
  const getSettings = () => {
    checkAdminConsent()
    axios
      .get('/api/admin/getGroupedTenantSettings')
      .then(response => {
        setSettings(response.data)
        localStorage.setItem('settings', JSON.stringify(response.data))
      })
      .catch(error => {
        console.log('SettingsContextProvider_getSettings Error: ' + error)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  const checkAdminConsent = async () => {
    try {
      await axios.get('api/common/checkAdminConsent')
      setHasAdminConsent(true)
    } catch (e) {
      setHasAdminConsent(false)
    }
  }

  // #endregion

  // #region local storage functions ===========================================================
  const getLocalStorageSettings = () => {
    try {
      const settings = localStorage.getItem('settings')
      return settings ? JSON.parse(settings) : null
    } catch (e) {
      return null
    }
  }

  // #endregion

  // #region Getter Functions =============================================================
  const getModuleSettings = module => {
    checkIfSettingsAreExpired()
    return settings[MODULES[module]]
  }

  const getCachedModuleSettings = module => {
    return settings?.[MODULES[module]] || getLocalStorageSettings()?.[MODULES[module]]
  }

  const getIsLoading = () => {
    return isLoading
  }

  const getHasAdminConsent = () => {
    return hasAdminConsent
  }

  const getSetting = ({ moduleName, setting }) => {
    checkIfSettingsAreExpired()
    return settings[moduleName]?.settings[setting]
  }

  // #endregion

  // #region module settings functions ===========================================================
  const isModuleHidden = module => {
    return !settings?.[module]
  }

  const isUpgradePageShown = module => {
    return !!settings?.[module]?.isUpgradePageShown
  }
  // #endregion

  // #region Handler Functinos ===========================================================

  /**
   * It changes the settings in the database and updates the settings in the state.
   * @param {string} setting - The setting to be changed.
   * @param {string} value - The value to be set.
   */
  const changeSettings = async ({
    moduleName,
    setting,
    value,
    subSetting,
    notAffectDatabase = false,
    notAffectSettingsContext = false,
    ...additionalProperties
  }) => {
    const relatedModuleSettings = getModuleSettings(moduleName)
    const tempModuleSettings = { ...relatedModuleSettings }
    let subSettingNameInDatabase = subSetting

    if (!notAffectSettingsContext) {
      // if module main setting is changed
      if (tempModuleSettings.moduleSettingName === setting) {
        tempModuleSettings.moduleEnabled = !value
      }
      // if subsetting is changed
      else if (subSetting) {
        let settingsObject = tempModuleSettings.settings[setting]

        const subSettingArray = subSetting.split('#')

        for (let i = 0; i < subSettingArray.length; i++) {
          settingsObject = settingsObject.subSettings[subSettingArray[i]]
        }

        subSettingNameInDatabase = subSettingArray[subSettingArray.length - 1]

        if (value !== undefined) {
          settingsObject.value = value
        }
        if (additionalProperties) {
          settingsObject = {
            ...settingsObject,
            ...additionalProperties
          }
        }
      }
      // if setting is changed
      else {
        if (value !== undefined) {
          tempModuleSettings.settings[setting].value = value
        }
        if (additionalProperties) {
          tempModuleSettings.settings[setting] = { ...tempModuleSettings.settings[setting], ...additionalProperties }
        }
      }

      const tempSettings = { ...settings }
      tempSettings[moduleName] = tempModuleSettings

      setSettings(tempSettings)
    }

    if (!notAffectDatabase) {
      setIsLoading(true)

      let body = {
        setting: setting,
        subSetting: subSetting,
        value: value,
        moduleName: MODULES[moduleName]
      }
      await axios
        .post('/api/admin/setTenantSetting', body)
        .then(response => {
          // if module main setting is changed
          if (tempModuleSettings.moduleSettingName === setting) {
            getSettings()
          }
        })
        .catch(error => {
          getSettings()
          console.log('SettingsContextProvider_changeSettings Error: ' + error)
        })
        .finally(() => {
          setIsLoading(false)
        })
    }
  }

  // #endregion

  // #region Helper Functions =============================================================

  /**
   * It calculates the enabled goal progress types based on the disabled goal progress types.
   * @param {Array} disabledGoalProgressTypes - The disabled goal progress types.
   * @returns {Array} - The enabled goal progress types.
   */
  const calculateEnabledGoalProgressTypes = disabledGoalProgressTypes => {
    const enabledGoalProgressTypes = []

    if (!disabledGoalProgressTypes || disabledGoalProgressTypes.length === 6) {
      return [
        'Percent',
        'Value',
        'Currency',
        'Completed/Not completed',
        'Roll-up from task progress',
        'Roll-up from subgoal progress'
      ]
    }

    // convert disable goal progress types to values
    const disabledGoalProgressTypesValues = disabledGoalProgressTypes.map(type => {
      switch (type) {
        case 1:
          return 'Percent'
        case 2:
          return 'Value'
        case 3:
          return 'Currency'
        case 4:
          return 'Completed/Not completed'
        case 5:
          return 'Roll-up from task progress'
        case 6:
          return 'Roll-up from subgoal progress'
        default:
          return type
      }
    })

    if (!disabledGoalProgressTypesValues.includes('Currency')) {
      enabledGoalProgressTypes.push('Currency')
    }
    if (!disabledGoalProgressTypesValues.includes('Percent')) {
      enabledGoalProgressTypes.push('Percent')
    }
    if (!disabledGoalProgressTypesValues.includes('Value')) {
      enabledGoalProgressTypes.push('Value')
    }
    if (!disabledGoalProgressTypesValues.includes('Completed/Not completed')) {
      enabledGoalProgressTypes.push('Completed/Not completed')
    }
    if (!disabledGoalProgressTypesValues.includes('Roll-up from task progress')) {
      enabledGoalProgressTypes.push('Roll-up from task progress')
    }
    if (!disabledGoalProgressTypesValues.includes('Roll-up from subgoal progress')) {
      enabledGoalProgressTypes.push('Roll-up from subgoal progress')
    }

    return enabledGoalProgressTypes
  }

  // Function to check if a module is disabled and show a warning message
  const checkForDisableMessage = module => {
    const moduleSettings = getModuleSettings(module)
    if (disableMessageRef.current && moduleSettings?.isDisabled) {
      disableMessageRef.current.replace({
        severity: 'warn',
        summary: (
          <span style={{ display: 'inline-block' }}>
            You are not allowed to change general and module settings. To access permissions, please contact your
            Teamflect Administrator(s).
          </span>
        ),
        sticky: true
      })
    }
  }

  const checkIfSettingsAreExpired = () => {
    if (moment().isAfter(validUntil)) {
      setValidUntil(moment().add(SETTINGS_TTL, 'minutes'))
      getSettings()
    }
  }

  // #endregion

  // #region Filter Functions =============================================================

  /**
   * Recursively filters nav items based on module settings
   * @param {Array} items - Array of nav items
   * @returns {Array} - Filtered array of nav items
   */
  const filterNavItemsByModuleSettings = items => {
    return items.reduce((acc, item) => {
      // first check if item is a module item
      if (item.data?.moduleKey) {
        // then check if module is hidden
        if (getCachedModuleSettings(item.data.moduleKey) === undefined) {
          return acc
        }
      }
      // if item is not a module item, add it to the accumulator
      acc.push(item)

      if (item.children) {
        item.children = filterNavItemsByModuleSettings(item.children)
      }

      return acc
    }, [])
  }

  /**
   * Filters navigation items based on module settings
   * @param {Object} navConfig - Navigation configuration object
   * @returns {Object} - Filtered navigation configuration
   */
  const filterNavByModuleSettings = navConfig => {
    let filteredNavConfig = structuredClone(navConfig)
    filteredNavConfig.items = filterNavItemsByModuleSettings(filteredNavConfig.items)
    return filteredNavConfig
  }

  /**
   * Filters routes based on module settings
   * @param {Array} routes - Array of route objects
   * @returns {Array} - Filtered array of routes
   */
  const filterRoutesByModuleSettings = routes => {
    // Create a map of nav paths to their module keys
    const navModuleMap = new Map()

    const mapRoutesToModules = items => {
      items.forEach(item => {
        if (item.data?.moduleKey) {
          navModuleMap.set(item.url, item.data.moduleKey)
        }
        if (item.children) {
          mapRoutesToModules(item.children)
        }
      })
    }

    // Map nav paths to modules
    mapRoutesToModules(navConfig.items)

    // Filter routes based on module settings
    return routes.filter(route => {
      // Find any nav path that matches this route
      const matchingNavPath = Array.from(navModuleMap.keys()).find(navPath => route.path.includes(navPath))

      if (!matchingNavPath) return true // Keep routes that aren't associated with modules

      const moduleKey = navModuleMap.get(matchingNavPath)
      return getCachedModuleSettings(moduleKey) !== undefined
    })
  }

  // #endregion

  return (
    <SettingsContext.Provider
      value={{
        getSettings,
        getSetting,
        getModuleSettings,
        changeSettings,
        getIsLoading,
        getHasAdminConsent,
        filterRoutesByModuleSettings,
        filterNavByModuleSettings,
        isModuleHidden,
        isUpgradePageShown,
        disableMessageRef,
        checkForDisableMessage
      }}
    >
      {props.children}
    </SettingsContext.Provider>
  )
}

/**
 * It returns the settings context.
 */
const useSettings = () => {
  return useContext(SettingsContext)
}

export { SettingsContextProvider, useSettings }
